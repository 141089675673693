import React from "react"
import { Link } from "gatsby";
import Header from "../Header"

export default function Home() {
    return <>
            <Header title="Personal Site"></Header>
            <p>Information Security, Guardium Guy, Data Visualization, Software Developer, Windsorite.</p>
            <ul>
                <li><Link to="projects">Projects</Link>: Some fun things I've built outside of work</li>
                <li><Link to="visualizations">Visualizations</Link>: One off visualizations of data</li>
                <li><Link to="presentations">Presentations</Link>: Links to some to PDFs of some presentations I have given</li>
                <li><Link to="blog">Blog</Link>: My blog</li>
            </ul>
            <p>Links to social media and other sites</p>
            <ul>
                <li><a href="https://twitter.com/JLHaldeman">Twitter</a></li>
                <li><a href="https://github.com/johnhaldeman">Github</a></li>
                <li><a href="https://observablehq.com/@johnhaldeman">Observable</a> (Javascript Notebooks)</li>
                <li><a href="https://www.linkedin.com/in/john-haldeman-1a106234/">LinkedIn</a></li>
                <li><a href="https://www.facebook.com/john.haldeman">Facebook</a></li>
            </ul>
    </>
}

